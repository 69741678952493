export const about = {
  PL: {
    title: 'Czym jest cHow?',
    youtubeVideoUrl: 'https://www.youtube.com/embed/1pFRmGIU_ng',
    essence:
      'cHow to platforma typu Retail Intelligence składająca się z części webowej oraz aplikacji mobilnych dla systemów iOS i Android. Zastępuje e-maile, checklisty i excel’e wszędzie tam, gdzie potrzebne jest szybkie zebranie i przejrzyste przedstawienie informacji z wielu miejsc i od wielu osób. Porządkuje i usprawnia komunikację na wszystkich szczeblach organizacji. Ułatwia kontrolę i dostarcza trafnych insight’ów.',
    btnText: 'Umów się na demo',
  },
  ENG: {
    title: 'What is cHow?',
    youtubeVideoUrl: 'https://www.youtube.com/embed/RDutQ0vBqwE',
    essence:
      'cHow is a Retail Intelligence platform, comprising of the website and the mobile applications for both iOS and Android system. It replaces e-mails, checklists and Excel files wherever there’s a need to quickly acquire and clearly present information from multiple places and multiple people. It organizes and streamlines the communication across all levels in the organization. It simplifies control and provides accurate insights.',
    btnText: 'Schedule a demo',
  },
};
