import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import LanguageSwitcher from '../../store/LanguageSwitcher';
import Loader from '../Loader/Loader';

const Layout = ({ children, type, onlyPL }) => (
  <main className='layout'>
    <Loader />
    {!onlyPL && <LanguageSwitcher />}
    <Header type={type} />
    {children}
    <Footer />
  </main>
);

export default Layout;
