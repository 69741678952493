import { useContext } from 'react';
import { Context } from '../store/context';
import { content } from './content';

const useContent = (contentName) => {
  const context = useContext(Context);
  if (!context) return content[contentName].PL;

  const [state] = context;

  return content[contentName][state.language];
};

export default useContent;
