export const header = {
  PL: {
    pages: [
      { id: 'btnHeader1', pageName: 'CZYM JEST CHOW?', pageId: 'about' },
      { id: 'btnHeader2', pageName: 'JAK TO DZIAŁA?', pageId: 'howIsItWorking' },
      { id: 'btnHeader3', pageName: 'DLA KOGO?', pageId: 'forWho' },
      { id: 'btnHeader4', pageName: 'ARTYKUŁY', pageId: 'articles' },
      { id: 'btnHeader5', pageName: 'KONTAKT', isBtn: true, pageId: 'contact', type: 1 },
    ],
    blog: [{ pageName: 'POZNAJ SYSTEM CHOW', isBtn: true, pageId: 'contact', type: 1 }],
  },
  ENG: {
    pages: [
      { id: 'btnHeader1', pageName: 'WHAT IS CHOW?', pageId: 'about' },
      { id: 'btnHeader2', pageName: 'HOW IT WORKS?', pageId: 'howIsItWorking' },
      { id: 'btnHeader3', pageName: 'USE CASES', pageId: 'forWho' },
      { id: 'btnHeader5', pageName: 'CONTACT US', isBtn: true, pageId: 'contact', type: 1 },
    ],
    blog: [{ pageName: 'GET TO KNOW THE CHOW', isBtn: true, pageId: 'contact', type: 1 }],
  },
};
