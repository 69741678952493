import React from 'react';

const Loader = () => {
  const isClient = typeof window === 'object';

  return (
    <>
      {!isClient && (
        <div className='loader'>
          <div className='spinner' />
        </div>
      )}
    </>
  );
};

export default Loader;
