import React from 'react';

export const landing = {
  PL: {
    title: 'Platforma do zbierania i analizy danych',
    titleOptions: ['z sieci sprzedaży', 'od pracowników terenowych', 'z rozproszonych organizacji', 'w Twojej firmie!'],
    essence:
      'Zadania dla sklepów, kierowników regionalnych, merchandiserów, przedstawicieli handlowych czy audytorów realizowane wygodnie w aplikacji mobilnej. Raporty w czasie rzeczywistym.',
    list: [
      'Utwórz dowolne scenariusze zadań i zgłoszeń.',
      'Zbieraj i analizuj odpowiedzi, zdjęcia, potwierdzone geolokalizacje, raporty z wizyt.',
      <>
        Przeglądaj dane w uporządkowanej formie, eksportuj, wnioskuj na ich podstawie i{' '}
        <span className='marker'>podejmuj trafne decyzje!</span>
      </>,
    ],
    btns: [{ name: 'UMÓW SIĘ NA DEMO' }, { name: 'DOWIEDZ SIĘ WIĘCEJ' }],
  },
  ENG: {
    title: 'A platform for gathering and analyzing data',
    titleOptions: ['retail chain', 'from the field workforce', 'from distibuted organizations', 'in your company!'],
    essence:
      'Tasks for your stores, your regional managers, merchandisers, sales representatives or auditors conveniently documented in a mobile application. Real-time reporting.',
    list: [
      'Create various task and report templates.',
      'Gather and analyze the answers, photos, confirmed geo-tags and visits',
      <>
        Browse the organized data, export, analyze further and <span className='marker'>make accurate choices!</span>
      </>,
    ],
    btns: [{ name: 'SCHEDULE A DEMO' }, { name: 'DISCOVER ME' }],
  },
};
