export const company = {
  PL: {
    companyName: 'cHow',
    companyCity: 'Kraków',
    companyAddress: 'Królewska 2/2, 30-045 Kraków',
  },
  ENG: {
    companyName: 'cHow',
    companyCity: 'Kraków',
    companyAddress: 'Królewska 2/2, 30-045 Kraków',
  },
};
