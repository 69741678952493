export const howIsItWorking = {
  PL: {
    title: 'Jak to działa?',
    essence:
      'Twórz scenariusze zadań i przydzielaj zadania do odpowiednich pracowników, monitoruj postępy, wysyłaj powiadomienia i analizuj wyniki w systemie raportowym.',
    steps: [
      {
        id: 1,
        title: 'Zdefiniuj proces',
        imgName: 'how1.png',
        description: [
          {
            title: 'Ustawienia projektu',
            content:
              'Wybierz spośród różnych typów zadań: w wybranych placówkach, w terenie, w oparciu o geolokalizację, powtarzalne zgłoszenia. Dzięki intuicyjnej konfiguracji odwzorujesz każdy proces.',
          },
          {
            title: 'Kwestionariusz',
            content:
              'Stwórz scenariusz zadania w oparciu o różne typy pytań, ich wzajemną logikę i zaawansowane zasady wyświetlania. Przydziel punktację, aby wygodnie śledzić wyniki.',
          },
        ],
      },
      {
        id: 2,
        title: 'Przydziel zadania',
        imgName: 'how2.png',
        description: [
          {
            title: 'Wybierz wykonawców',
            content: 'Przypisuj zadania na podstawie rozbudowanego modelu odwzorowania struktury organizacyjnej.',
          },
          {
            title: 'Ustaw harmonogramy',
            content: 'Ustaw powtarzalność, cykliczność i automatyczne tworzenie zadań na podstawie różnych zdarzeń.',
          },
        ],
      },
      {
        id: 3,
        title: 'Monitoruj postępy',
        imgName: 'how3.png',
        description: [
          {
            title: 'Monitoruj terminowość',
            content:
              'Wygodnie przeglądaj postępy realizacji projektów i wyniki poszczególnych placówek, pracowników i regionów. Ustawiaj automatyczne powiadomienia (mail i push) o wybranych zdarzeniach w projekcie.',
          },
          {
            title: 'Przekazuj uwagi',
            content:
              'Kieruj powiadomienia do wybranych osób ręcznie lub na podstawie stałych mechanizmów. Sprawdzaj poprawność wykonywanych zadań i wygodnie wskazuj elementy wymagające poprawy.',
          },
        ],
      },
      {
        id: 4,
        title: 'Analizuj wyniki',
        imgName: 'how4.png',
        description: [
          {
            title: 'Przeglądaj dashboardy',
            content:
              'Analizuj sytuację bieżącą, trendy i zmiany w rozbudowanym systemie raportowym. Wyświetlaj dane w oparciu o uprawnienia użytkownika i przypisane do niego placówki i projekty.',
          },
          {
            title: 'Eksportuj i integruj',
            content:
              'Eksportuj dane w formie wygodnych arkuszy i paczek zdjęć. Integruj cHow z innymi systemami w firmie za pośrednictwem API lub dedykowanych integracji.',
          },
        ],
      },
    ],
  },
  ENG: {
    title: 'How it works?',
    essence:
      'Create task scenarios and assign them to appropriate people, monitor the progress, send out notifications and analyze the results in the reporting systems.',
    steps: [
      {
        id: 1,
        title: 'Define the process',
        imgName: 'how1.png',
        description: [
          {
            title: 'Project settings',
            content:
              'Choose from the various task types: in selected locations, in the field, based on geolocation, repeatable assignments. Thanks to an intuitive configuration you will be able to map any process.',
          },
          {
            title: 'Questionnaire',
            content:
              'Create the task scenario using different types on questions, their mutual logic and advanced availability rules. Assign scoring to track the results easily.',
          },
        ],
      },
      {
        id: 2,
        title: 'Assign the tasks',
        imgName: 'how2.png',
        description: [
          {
            title: 'Choose the contractor',
            content: 'Assign the task based on the map reflecting your organizational structure.',
          },
          {
            title: 'Setup schedules',
            content: 'Setup repetitions, cycles and automatic creation of tasks based on various events.',
          },
        ],
      },
      {
        id: 3,
        title: 'Monitor the progress',
        imgName: 'how3.png',
        description: [
          {
            title: 'Track the punctuality',
            content:
              'Conveniently browse the progress of the project execution, and the results reported at particular locations, by particular contractors or in particular regions. Setup automatic notifications (push and e-mail) about the selected events happening in the project.',
          },
          {
            title: 'Send feedback',
            content:
              'Send the notifications to hand-picked recipients or based on defined mechanisms. Verify the correctness of the executed assignments and conveniently point out the items that need to be corrected.',
          },
        ],
      },
      {
        id: 4,
        title: 'Analyze the results',
        imgName: 'how4.png',
        description: [
          {
            title: 'Browse dashboards',
            content:
              'Assess the current situation, trends and changes in an expanded reporting system. Display the data based on the permissions, projects and locations bound to the particular system user.',
          },
          {
            title: 'Export and integrate',
            content:
              'Export the data in the form of accessible spreadsheets and picture archives. Integrate cHow with other corporate systems over an API or proprietary integration interfaces.',
          },
        ],
      },
    ],
  },
};
