export const freeRaport = {
  PL: {
    imgUrl: 'report-cover.png',
    title1: 'Bezpłatny raport',
    title2: 'Zachowania konsumenckie wobec pandemii',
    essence:
      'Dowiedz się, jak pandemia wpłynęła na zachowania i odczucia polskich konsumentów i co z tego wynika dla Twojej firmy!',
    btnText: 'Pobierz darmowy raport',
    modal: {
      modalText: 'Podaj Twój adres e-mail, na który natychmiast prześlemy Ci pełną wersję raportu',
      modalForm: {
        formSubmitText: 'Wyślij formularz',
        formFields: [
          { fieldId: 1, fieldKey: 'name', fieldType: 'text', fieldLabel: 'Imię i nazwisko', isRequired: true },
          { fieldId: 4, fieldKey: 'mail', fieldType: 'email', fieldLabel: 'E-mail', isRequired: true },
          { fieldId: 2, fieldKey: 'company', fieldType: 'text', fieldLabel: 'Firma' },
          { fieldId: 3, fieldKey: 'phone', fieldType: 'tel', fieldLabel: 'Telefon' },
          {
            fieldId: 5,
            fieldKey: 'isRightAccepted',
            fieldType: 'right',
            fieldText:
              'Zgadzam się na kontakt w sprawie krótkiej rozmowy dotyczącej raportu oraz potencjalnego wykorzystania cHow w mojej firmie.',
            fieldLabel: 'Zgoda kontaktowa',
          },
        ],
      },
      successMassage: 'Raport został wysłany',
      failMassage: 'Popraw błędnie wypełnione pola: ',
    },
  },
};
