import React, { useState, useEffect, useContext } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'gatsby';

import ChowMainLogo from '../../assets/chow-main-logo.svg';
import useWindowSize from '../../hooks/useWindowSize';
import MobileNavigation from './MobileNavigation';
import useScroll from '../../hooks/useScroll';
import useContent from '../../content/useContent';
import { scrollToId } from '../../utils/scrollToId';
import Image from "../Image/Image";
import {Context} from "../../store/context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

const Header = ({ type }) => {
  const content = useContent('header');

  const context = useContext(Context);
  if (!context) return null;
  const [state, dispatch] = context;
  const { language } = state;

  const { pages, blog } = content;

  const { aboveDesktop } = useWindowSize();

  const [isMobileNavigationVisible, setIsMobileNavigationVisible] = useState(null);

  const { scrollY } = useScroll();
  const isScrolled = scrollY > 0;
  const [isScrolledBack, setIsScrolledBack] = useState(false);

  useEffect(() => {
    if (isScrolled) setIsScrolledBack(true);
  }, [isScrolled]);

  const getScrolledClass = () => {
    if (isScrolled) return 'scrolled';
    if (isScrolledBack) return 'scrolled-back';
    return '';
  };

  return (
    <header className={`header ${getScrolledClass()}`}>
        {/*{(aboveDesktop && language === 'PL') && (*/}
        {/*    <div className={'raport-bar'}>*/}
        {/*        <div>*/}
        {/*            <Image filename={'small-report-cover.png'}/>*/}
        {/*        </div>*/}
        {/*        <h5>POBIERZ RAPORT</h5>*/}
        {/*        <h6>"Zachowania konsumenckie wobec pandemii"</h6>*/}
        {/*        <a href={'/#raport'}>POBIERZ RAPORT <FontAwesomeIcon icon={faCaretRight} /></a>*/}
        {/*    </div>*/}
        {/*)}*/}
      <div className={`header__inside boxed `}>
        <div className='header__logo-wrapper'>
          {blog ? (
            <Link to='/' id='btnHeaderGoHome'>
              <ChowMainLogo />
            </Link>
          ) : (
            <div id='btnHeaderGoTop'>
              <ChowMainLogo onClick={() => scroll.scrollToTop()} />
            </div>
          )}
        </div>
        {!aboveDesktop ? (
          <>
            <button
              type='button'
              className='hamburger'
              onClick={() => setIsMobileNavigationVisible((prevState) => !prevState)}
            >
              <div className='hamburger__stripe' />
              <div className='hamburger__stripe' />
              <div className='hamburger__stripe' />
            </button>

            <MobileNavigation
              isVisible={isMobileNavigationVisible}
              setIsVisible={setIsMobileNavigationVisible}
              type={type}
              blog={blog}
            />
          </>
        ) : (
          <nav className='desktop-navigation header__navigation'>
            {type !== 'blog'
              ? pages.map(({ id, pageName, isBtn, pageId }) => (
                  <a
                    key={pageName}
                    id={id}
                    onClick={() => scrollToId(pageId)}
                    className={`${isBtn ? 'btn btn--1' : 'header__link'}`}
                  >
                    {pageName}
                  </a>
                ))
              : blog &&
                blog.map(({ id, pageName, isBtn, pageId }) => (
                  <Link to='/' id='btnHeaderGoHome'>
                    <a
                      key={pageName}
                      id={id}
                      // onClick={() => scrollToId(pageId)}
                      className={`${isBtn ? 'btn btn--1' : 'header__link'}`}
                    >
                      {pageName}
                    </a>
                  </Link>
                ))}
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
