import { useState, useEffect } from 'react';

const useBrowserLanguage = () => {
  const isClient = typeof window === 'object';
  const ENG = 'en-US';
  const PL = 'pl-PL';

  const [languageString, setLanguageString] = useState(PL);

  useEffect(() => {
    if (!isClient) return;

    const language = window.navigator.userLanguage || window.navigator.language;
    setLanguageString(language);
  }, [isClient]);

  return languageString === ENG ? 'ENG' : 'PL';
};

export default useBrowserLanguage;
