export const blogPosts = {
  PL: {
    btnText: 'Przeczytaj artykuł',
    title: 'Artykuły',
    posts: [
      {
        postImgUrl: 'zdrowysklep.jpg',
        postTitle: '„Zdrowy Sklep” - zarządzanie siecią sprzedaży w pandemii',
        postExcerpt:
          'Dostosuj swoją sieć do nowej rzeczywistości i bądź gotowy na kolejne zmiany! Buduj z cHow „zdrowe sklepy” - sklepy, w których zarówno pracownicy jak i klienci będą czuli się bezpiecznie.',
        postUrl: 'nowe-potrzeby-klientow-nowe-oblicze-sklepow',
      },
      {
        postImgUrl: 'monnari.jpg',
        postTitle: '„Zarządzanie rozproszonym zespołem nie należy do łatwych zadań”',
        postExcerpt:
          'O tym, jak realizację tego zadania uprościć oraz na czym dokładnie polega wyzwanie, rozmawialiśmy z Dyrektorem Operacyjnym Monnari Trade, panem Piotrem Olczykiem.',
        postUrl: 'zarzadzanie-rozproszona-struktura-nie-nalezy-do-latwych-zadan',
      },
      {
        postImgUrl: 'raportuj.png',
        postTitle: '„Raportuj zwinnie - decyduj bez zgadywania”',
        postExcerpt:
          'O tym, jak realizację tego zadania uprościć oraz na czym dokładnie polega wyzwanie, rozmawialiśmy z Dyrektorem Operacyjnym Monnari Trade, panem Piotrem Olczykiem.',
        postUrl: 'raportuj-zwinnie-decyduj-bez-zgadywania',
      },
    ],
  },
  ENG: {
    btnText: 'Read article',
    title: 'Articles',
    posts: [
      {
        postImgUrl: 'zdrowysklep_eng.png',
        postTitle: '„Healthy store” - managing the sales network in a pandemic times',
        postExcerpt:
          'Adapt your network to the new reality and be ready for the next changes! Build "healthy stores" with cHow - stores, where employees and customers will feel safe.',
        postUrl: 'new-customer-needs-the-new-face-of-the-store',
      },
      {
        postImgUrl: 'monnari.jpg',
        postTitle: '„Managing a field team is not an easy job”',
        postExcerpt:
            'On how to make this task simpler and where exactly lies the challenge, we talk to the Director of Operations at Monnari Trade, Piotr Olczyk.',
        postUrl: 'managing-a-field-team-is-not-an-easy-job',
      },
      {
        postImgUrl: 'raports-eng.png',
        postTitle: '„Meet agile reporting – decide without guessing”',
        postExcerpt:
          'You’re wasting hours looking for data in emails and tables just to find out that the situation in you retail chain might be.',
        postUrl: 'meet-agile-reporting–decide-without-guessing',
      },
    ],
  },
};
