export const usageExamples = {
  PL: {
    title: 'Przykłady użycia',
    beforeTitle: 'Przed wdrożeniem cHow',
    afterTitle: 'Po wdrożeniu cHow',
    modalTitle: 'Przykład użycia c-How',
    modalCloseText: 'Zamknij okno',
    modalRequestDemoText: 'Umów się na demo',
    usages: [
      {
        usageId: 1,
        usageImgUrl: 'monnari.jpg',
        usageTitle: 'Sieć sklepów spożywczych',
        usageTags: ['Bieżący monitoring', 'Efektywność'],
        usageText:
          'Sklepy franczyzowe marki działają na terenie całej Polski. Sieć obejmuje zarówno niewielkie placówki - oferujące podstawowy asortyment produktów - jak i większe sklepy o rozbudowanej palecie produktów. Zarządzanie tak różnorodną strukturą wiąże się z licznymi wyzwaniami operacyjnymi. Aby móc im sprostać, managerowie potrzebują każdego dnia bieżących informacji z kilkuset sklepów. Niezbędna jest też możliwość efektywnej ustrukturyzowanej komunikacji z grupami personelu.',
        beforeAfter: [
          {
            before:
              'Papierowe formularze wypełniane przez kierowników regionalnych wypełniane były wyłącznie w czasie rutynowej wizyty w sklepie - kilka razy w miesiącu.',
            after: 'Kierownicy sklepów lub kierownicy zmiany wypełniają rutynowe raporty trzy razy dziennie.',
            modalTags: ['Bieżący monitoring', 'Efektywność'],
          },
          {
            before:
              'W centrali pracownicy przepisywali dane z formularzy do komputerów. Zbiorcze dane za dany miesiąc dostępne dla osób zarządzających dopiero na początku kolejnego miesiąca.',
            after:
              'Osoby zarządzające widzą dane w czasie rzeczywistym w systemie online. Zyskały więc możliwość podejmowania działań interwencyjnych.',
            modalTags: ['Koszty pracy', 'Bieżący monitoring', 'Reakcja na bieżące wydarzenia'],
          },
          {
            before:
              'Nie było możliwości incydentalnego zebrania informacji np. na temat realizowanej właśnie akcji promocyjnej. Jedyne kanały pozyskania wiadomości ze sklepów to maile i smsy, na które część osób po prostu nie odpowiadała. Zebrane odpowiedzi trzeba było przepisać do jednego arkusza, aby móc je analizować.',
            after:
              'Przepływ informacji stał się płynny. Managerowie pozyskują sprawnie wszystkie potrzebne im dane. Pracownicy niewywiązujący się z obowiązku raportowania mogą być upominani. Sprawność komunikacyjna stała się jednym ze wskaźników jakości pracy.',
            modalTags: ['Bieżący monitoring', 'Efektywność', 'Reakcja na bieżące wydarzenia'],
          },
          {
            before:
              'W centrali firmy brakowało informacji o bieżącej sytuacji w sklepach. Informacje pozyskiwane były raczej w oparciu o impuls. Np. po wystąpieniu krytycznego zdarzenia, dany sklep był częściej sprawdzany. Nie było narzędzia sprzyjającego budowaniu kultury samodoskonalenia.',
            after:
              'Sytuacja w każdym ze sklepów monitorowana jest kilka na bieżąco - dane spływają kilka razy dziennie. Managerowie mogą pogłębiać informacje z rutynowych raportów zadając za pośrednictwem systemu dodatkowe pytania.',
            modalTags: ['Bieżący monitoring', 'Efektywność', 'Reakcja na bieżące wydarzenia'],
          },
        ],
        usageBtnText: 'Dowiedz się więcej',
      },
      {
        usageId: 2,
        usageImgUrl: 'monnari.jpg',
        usageTitle: 'Sieć stacji benzynowych',
        usageTags: ['koszty pracy', 'bieżący monitoring', 'szybkie reagowanie'],
        usageText:
          'Pracownicy działu kontroli wewnętrznej odbywają rutynowe wizyty na stacjach. Po każdej z nich raportują wiele aspektów działalności - realizację założeń akcji promocyjnych, czystość, zatowarowanie itp. Dane z tych raportów - Kart Ocen Stacji - przekazywane są do odpowiednich działów, by te na ich podstawie podjęły działania korygujące. Z danych w formie skumulowanej korzysta w procesach decyzyjnych zarząd firmy.',
        beforeAfter: [
          {
            before:
              'Kierownicy regionów wypełniali Karty Oceny Stacji w formie papierowej lub w laptopie - w zależności od preferencji. Kompletna Karta wysyłana była mailem do centrali - do wielu pracowników różnych działów.',
            after: 'Kierownicy regionów pracują z aplikacją i w niej wypełniają Karty Oceny Stacji.',
            modalTags: ['Koszty pracy', 'Efektywność'],
          },
          {
            before:
              'W centrali pracownicy różnych działów wybierali z Karty Oceny Stacji te fragmenty, które ich dotyczą. Punkty wynikające z całości oceny wpisywane były do systemu komputerowego, ponieważ są związane z systemem premiowym.',
            after:
              'Osoby zarządzające widzą dane w czasie rzeczywistym w systemie online. Widzą tylko to, co ich dotyczy - nie muszą selekcjonować danych. Pojawiła się możliwość podejmowania działań interwencyjnych. Istnieje możliwość zlecania dodatkowych kontroli w obszarach wymagających uwagi.',
            modalTags: ['Koszty pracy', 'Bieżący monitoring', 'Reakcja na bieżące wydarzenia'],
          },
          {
            before:
              'Kierownicy regionalni jeździli rutynowo wyznaczonymi trasami. Były one ułożone nie ze względu na potrzeby, ale ze względu na optymalizację tras.',
            after:
              'Planowanie pracy stało się niezwykle elastyczne - Kierownik regionu może być na bieżąco informowany o nowych taskach. Pracuje tam, gdzie jest taka potrzeba, a nie tam, gdzie akurat jest po drodze.',
            modalTags: ['Efektywność'],
          },
          {
            before:
              'Zarząd otrzymywał liczne raporty na temat sytuacji w sieci - każdy skupiony na jednym temacie np. bar, paliwa i myjnia, HR (w tym potrzeby szkoleniowe). Aby zyskać całościowe spojrzenie na sieć, konieczne było robienie zestawień wartości wynikających z tych raportów.',
            after:
              'Informacje o charakterze zarządczym dostępne są online. Robienie pożądanych raportów jest intuicyjne, zajmuje mało czasu i absorbuje znacznie mniejszą grupę pracowników.',
            modalTags: ['Planowanie', 'Zarządzanie pracą', 'Reakcja na bieżące wydarzenia', 'Koszty pracy'],
          },
          {
            before: '',
            after:
              'Ze względu na łatwość raportowania krótkich informacji, Regionalni proszeni są także o monitorowanie cen na stacjach konkurencji. Wypełniają w aplikacji online raporty, z których w czasie rzeczywistym korzystają pracownicy centrali.',
            modalTags: ['Dodatkowe informacje'],
          },
        ],
        usageBtnText: 'Dowiedz się więcej',
      },
      {
        usageId: 3,
        usageImgUrl: 'monnari.jpg',
        usageTitle: 'Firma ubezpieczeniowa',
        usageTags: ['koszty pracy', 'bieżący monitoring', 'szybkie reagowanie'],
        usageText:
          'Po każdych oględzinach obiektu, który został uszkodzony i jest przedmiotem procedury ubezpieczeniowej (np. samochód), rzeczoznawcy wypełniają protokół z opisem tego, co stwierdzili. Odbywa się to w różnych - czasami niekomfortowych - warunkach. Informacje z protokołu stanowią punkt wyjścia do dalszych kroków procesu związanego z wypłatą pieniędzy z polisy ubezpieczeniowej.',
        beforeAfter: [
          {
            before:
              'Papierowe formularze wypełniane przez rzeczoznawców w miejscu oględzin. Wysyłane w formie papierowej do centrali lub skanowane i wysyłane mailem.',
            after:
              'Rzeczoznawcy pracują z aplikacją i w niej wypełniają protokoły. Czas przekazania danych do centrali został skrócony o 70%.',
            modalTags: ['Koszty pracy', 'Efektywność'],
          },
          {
            before: 'W centrali pracownicy przepisywali dane z formularzy do komputerów.',
            after:
              'Osoby zarządzające widzą dane w czasie rzeczywistym w systemie online. Zyskały więc możliwość podejmowania działań interwencyjnych. Pracownicy operacyjni mogą podejmować kroki związane z realizacją danego zgłoszenia od razu po zakończeniu oględzin przez rzeczoznawcę.',
            modalTags: ['Koszty pracy', 'Bieżący monitoring', 'Reakcja na bieżące wydarzenia', 'Zadowolenie klientów'],
          },
          {
            before:
              'Rozdzielanie prac między rzeczoznawców miało charakter analogowy. Trudność wprowadzania modyfikacji na bieżąco.',
            after:
              'Planowanie pracy stało się niezwykle elastyczne - rzeczoznawca może być na bieżąco informowany o nowych taskach i zmianach w nich, gdy np. klient firmy ubezpieczeniowej zgłosi, że spóźni się na miejsce planowanych oględzin.',
            modalTags: ['Planowanie', 'Zarządzanie pracą', 'Reakcja na bieżące wydarzenia'],
          },
        ],
        usageBtnText: 'Dowiedz się więcej',
      },
    ],
  },
  ENG: {
    title: 'Use cases',
    beforeTitle: 'Before introducing cHow',
    afterTitle: 'After introducing cHow',
    modalTitle: 'Use cases',
    modalCloseText: 'Close',
    modalRequestDemoText: 'Schedule a demo',
    usages: [
      {
        usageId: 1,
        usageImgUrl: 'monnari.jpg',
        usageTitle: 'Food retail chain',
        usageTags: ['Ongoing monitoring', 'Effectiveness'],
        usageText:
          'Franchise shops are located all across Poland. The chain comprises of both small shops offering just selected items, as well as larger establishments with a broader product offer. Managing such a diverse structure poses several operational challenges. To tackle them effectively, the managers need up-to-date data from hundreds of locations. It is essential to effectively structure the communication with different groups of employees.',
        beforeAfter: [
          {
            before:
              'Paper forms filed by the regional managers only occasionally - while routinely visiting the store, a few times a month.',
            after: 'Store managers or shift managers file routine reports three times a day.',
            modalTags: ['Ongoing monitoring', 'Effectiveness'],
          },
          {
            before:
              'At the headquarters, the reports were entered into the computer system. The monthly aggregated reports for the management was available no sooner than the beginning of the next month.',
            after:
              'The management is able to see the data in real-time in an online system. They now have a possibility to carry out corrective actions.',
            modalTags: ['Labour costs', 'Ongoing monitoring', 'Reaction to current events'],
          },
          {
            before:
              'There was no way to incidentally collect pieces of information, i.e. regarding the currently ongoing promotion. Only information channels were short texts and e-mails, which at times were left unanswered. The replies needed to be keyed into a spreadsheet for any analysis to be possible.',
            after:
              'The information exchange has become fluent. Managers get all the data they need in a streamlined fashion. The field workers failing to provide the requested information can be notified. Communication efficiency has become one of the indicators of work quality.',
            modalTags: ['Ongoing monitoring', 'Effectiveness', 'Reaction to current events'],
          },
          {
            before:
              'The headquarters lacked the information about the current situation in the stores. It was acquired mora as a response to an impulse, i.e. after an occurrence of a critical event. There was no tool supporting the self-improvement culture.',
            after:
              'The situation in every store is being continuously monitored - the data is provided a couple of times a day. The managers can drill down the information contained in the routine reports by asking additional questions using the system.',
            modalTags: ['Ongoing monitoring', 'Effectiveness', 'Reaction to current events'],
          },
        ],
        usageBtnText: 'Read more',
      },
      {
        usageId: 2,
        usageImgUrl: 'monnari.jpg',
        usageTitle: 'Petrol station chain',
        usageTags: ['LABOR COSTS', 'PERFORMANCE', 'REACTION TO EVENTS'],
        usageText:
          'The company`s internal auditors make routine visits to their petrol stations. After each visit they report numerous issues – the execution of promotional activities, cleanliness, supply status etc. The data in these reports – Station Assessment Sheets – are delivered to the appropriate departments within the organization so they can take corrective actions. The aggregate data is used by management to get a broad view of the overall situation within the company in order to make strategic decisions.',
        beforeAfter: [
          {
            before:
              'Regional managers filled out the Station Assessment Sheets on paper or using their laptops, whichever they preferred. The completed sheets were sent via email to HQ – to various employees in different departments.',
            after: 'Regional managers use the mobile application to fill in the Station Assessment Sheets.',
            modalTags: ['LABOR COSTS', 'PERFORMANCE'],
          },
          {
            before:
              'At HQ the employees in different departments extracted relevant sections of the Station Assessment Sheets. The overall scores were entered into the computer system since they were linked to the bonus system',
            after:
              'Management is able to view the data in real-time using the online system. They see only the sections relevant for them so they don`t have to filter out unnecessary data. A space for corrective actions emerges. There is a possibility to demand additional checks in areas requiring attention.',
            modalTags: ['LABOR COSTS', 'REAL-TIME MONITORING', 'REACTION TO EVENTS'],
          },
          {
            before:
              'Regional managers travelled routinely designated routes. They were calculated without considering demand, but with route optimization in mind.',
            after:
              'Work planning becomes flexible – regional managers can be instantly notified about new tasks. They work only where it is required, not where they happen to be or travel.',
            modalTags: ['PERFORMANCE'],
          },
          {
            before:
              'Management would receive numerous reports – each focused on a specific area i.e. bar, fuel and car wash, HR (including training purposes). To get a view of the total picture they needed to compile all of the results from all of the various reports.',
            after:
              'Management support data is available online. Getting the required reports is intuitive, quick and involves far fewer employees.',
            modalTags: ['PLANNING', 'WORK MANAGEMENT', 'REACTION TO EVENTS', 'LABOR COSTS'],
          },
          {
            before: '',
            after:
              'The ease of reporting short pieces of information allows regional managers to monitor the prices in competing petrol stations. They report it using the application and HQ can use the data in real time.',
            modalTags: ['ADDITIONAL INFORMATION'],
          },
        ],
        usageBtnText: 'Read more',
      },
      {
        usageId: 3,
        usageImgUrl: 'monnari.jpg',
        usageTitle: 'Insurance company',
        usageTags: ['PLANNING', 'WORK MANAGEMENT', 'REACTION TO EVENTS'],
        usageText:
          'After inspecting a damaged object that is the subject of an insurance procedure (i.e. a crashed car), insurance experts are compelled to fill out a protocol with a description of their findings. These process often happens in stressful and even uncomfortable circumstances. The protocol is a prerequisite for the next steps of the process of reimbursement.',
        beforeAfter: [
          {
            before:
              'Paper forms filled out by experts at the inspection site. Sent in paper form or scanned and emailed to HQ.',
            after:
              'Experts work using the application and fill out all protocols online. Delivery time for sending protocols to HQ is cut by 70%.',
            modalTags: ['LABOR COSTS', 'PERFORMANCE'],
          },
          {
            before: 'At HQ, employees entered the forms into a computer system.',
            after:
              'Management is able to see incoming data in real time, which gives them a chance to intervene if necessary. Operational staff can carry out the usual steps immediately after receiving protocols.',
            modalTags: ['LABOR COSTS', 'REAL-TIME MONITORING', 'REACTION TO EVENTS', 'CUSTOMER SATISFACTION'],
          },
          {
            before: 'Assigning tasks to experts was a manual process, difficult to modify once completed.',
            after:
              'Work planning became very flexible – experts can be notified about new tasks and alterations to existing ones i.e. when clients warn about being late to an inspection site.',
            modalTags: ['PLANNING', 'WORK MANAGEMENT', 'REACTION TO EVENTS'],
          },
        ],
        usageBtnText: 'Read more',
      },
    ],
  },
};
