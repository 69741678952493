export const contact = {
  PL: {
    title: 'Kontakt',
    demo: {
      demoImg: 'chow-ilustration2.png',
      demoTitle: 'Zobacz demo',
      demoText: 'Umów się na prezentację i przetestuj cHow w swojej organizacji',
    },
    consultant: {
      consultantImg: 'mkosmider.jpg',
      consultantName: 'Magdalena Kośmider',
      consultantProfession: 'Customer Happiness Hero',
      consultantTel: '+48 531 54 54 49',
      consultantEmail: 'magdalena.kosmider@c-how.io',
      consultantDetail: 'Pokaż dane kontaktowe',
    },
    form: {
      formSubmitBtnText: 'Wyślij wiadmomość',
      formFields: [
        { fieldId: 1, fieldKey: 'name', fieldType: 'text', fieldLabel: 'Imię i nazwisko', isRequired: true },
        { fieldId: 2, fieldKey: 'company', fieldType: 'text', fieldLabel: 'Firma' },
        { fieldId: 3, fieldKey: 'phone', fieldType: 'text', fieldLabel: 'Telefon', isRequired: true },
        { fieldId: 4, fieldKey: 'mail', fieldType: 'text', fieldLabel: 'E-mail', isRequired: true },
        { fieldId: 5, fieldKey: 'message', fieldType: 'textarea', fieldLabel: 'Wiadomość' },
        { fieldId: 6, fieldKey: 'captcha', fieldType: 'captcha', fieldLabel: 'Weryfikacja reCAPTCHA' },
      ],
      errorMessage: 'Coś poszło nie tak, spróbuj ponownie później',
      successMassage: 'Wiadomość została wysłana',
      failMassage: 'Popraw błędnie wypełnione pola: ',
    },
  },
  ENG: {
    title: 'Contact us',
    demo: {
      demoImg: 'chow-ilustration2.png',
      demoTitle: 'Show demo',
      demoText: 'Request a presentation and test cHow in your company',
    },
    consultant: {
      consultantImg: 'mkosmider.jpg',
      consultantName: 'Magdalena Kośmider',
      consultantProfession: 'Customer Happiness Hero',
      consultantTel: '+48 531 54 54 49',
      consultantEmail: 'magdalena.kosmider@c-how.io',
      consultantDetail: 'Show contact details',
    },
    form: {
      formSubmitBtnText: 'Send message',
      formFields: [
        { fieldId: 1, fieldKey: 'name', fieldType: 'text', fieldLabel: 'Name', isRequired: true },
        { fieldId: 2, fieldKey: 'company', fieldType: 'text', fieldLabel: 'Company' },
        { fieldId: 3, fieldKey: 'phone', fieldType: 'text', fieldLabel: 'Phone', isRequired: true },
        { fieldId: 4, fieldKey: 'mail', fieldType: 'text', fieldLabel: 'E-mail', isRequired: true },
        { fieldId: 5, fieldKey: 'message', fieldType: 'textarea', fieldLabel: 'Message' },
        { fieldId: 6, fieldKey: 'captcha', fieldType: 'captcha', fieldLabel: 'Verification reCAPTCHA' },
      ],
      errorMessage: 'Something went wrong, try again later',
      successMassage: 'Message was send',
      failMassage: 'Please fill in the field correctly: ',
    },
  },
};
