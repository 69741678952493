export const mainClients = {
  PL: {
    title: 'Wykorzystywany z sukcesami przez liderów na swoich rynkach',
    logoUrls: ['logo1.png', 'logo2.png', 'logo3.png', 'logo4.png'],
  },
  ENG: {
    title: 'USED WITH SUCCESS BY THE LEADERS IN THEIR RESPECTIVE MARKETS',
    logoUrls: ['logo1.png', 'logo2.png', 'logo3.png', 'logo4.png'],
  },
};
