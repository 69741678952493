import { header } from './parts/header.content';
import { landing } from './parts/landing.content';
import { about } from './parts/about.content';
import { mainClients } from './parts/mainClients.content';
import { howIsItWorking } from './parts/howIsItWorking.content';
import { forWho } from './parts/forWho.content';
import { usageExamples } from './parts/usageExamples.content';
import { freeRaport } from './parts/freeRaport.content';
import { blogPosts } from './parts/blogPosts.content';
import { contact } from './parts/contact.content';
import { company } from './parts/company.content';
import { trustedUs } from './parts/trustedUs.content';
import { blogPost2 } from './parts/blogPost2.content';
import { blogPost3 } from './parts/blogPost3.content';

export const content = {
  header,
  landing,
  about,
  mainClients,
  howIsItWorking,
  forWho,
  usageExamples,
  freeRaport,
  blogPosts,
  contact,
  company,
  trustedUs,
  blogPost2,
  blogPost3,
};
