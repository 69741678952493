export const blogPost3 = {
  PL: {
    img: 'raportuj-icon.png',
    desc: 'Katarzyna Zalass - Digital Transformation Architect',
    title: 'Raportuj zwinnie - decyduj bez zgadywania',
    excerpt:
      'Tracisz godziny na poszukiwanie informacji w mailach i tabelach, aby zorientować się, jaka jest sytuacja w sieci sprzedaży. Po żmudnym procesie poszukiwań często okazuje się, że kluczowe z twojego punktu widzenia dane są niedostępne lub błędnie skategoryzowane. Wymagają obróbki. Mijają kolejne godziny… Nawet jeśli wszystkie dane są dostępne, ze względu na różnice w logice gromadzenia niełatwo wyczytać z nich odpowiedzi na nurtujące cię w danej chwili pytania. Czas nieubłaganie ucieka. Gdy już wyciągniesz wnioski, do których zmierzałeś i chciałbyś zacząć działać, sytuacja w sieci jest inna. Ale jaka?',
    sectionOne: {
      firstP:
        'Istnieje prosty sposób na to, by nie musieć decydować na przykład w oparciu o informacje o trendach zminionego miesiąca i po części zgadując: wdrożenie zwinnego raportowania.',
      list: {
        pos1: 'Dane z terenu wpływają w jedno miejsce, ',
        pos2: 'są ustandaryzowane,',
        pos3: 'zawierają wszystkie oczekiwane elementy, ',
        pos4:
          'dzięki spójnemu systemowi organizacji danych łatwo zapewnić ciągły dostęp do istotnych informacji dlawszystkich członków zespołu!',
      },
      secondP:
        'Z punktu widzenia osób pracujących z rozproszonymi zespołami zwinne raportowanie z terenu oznacza pozyskiwanie wysokiej jakości danych, łatwiejszych do wykorzystania w codziennych procesach decyzyjnych. Dzięki temu działania mogą być realizowane natychmiast, natychmiast wprowadzać można poprawki, a nowe insighty pojawiają się w trybie ciągłym.',
      thirdP:
        'Dzięki systemom dedykowanym zwinnemu raportowaniu – takim jak cHow – dane stają się w organizacjach fundamentem procesu decyzyjnego.',
    },
    sectionTwo: {
      title: 'Spójne dane – brak konieczności obróbki ',
      firstP:
        'Efektywne raportowanie wymaga pozyskiwania danych gotowych do analizy. Jeżeli zbieramy informacje o cenie produktu, możemy się spodziewać, że różne osoby wpiszą je w różny sposób np. 5.25, 5,25, 5 zł 25 gr. Gdy tak się dzieje, przed przystąpieniem do analizy, trzeba poświęcić sporo czasu na obróbkę danych – ichujednolicenie. Aby tego uniknąć, wystarczy użyć odpowiedniego typu pytania w systemie do raportowania. Użytkownicy wpisują dane wyłącznie w pożądanej formie, ponieważ inna nie zostanie przez system zaakceptowana.',
      secondP:
        'Do dyspozycji managerów zarządzających pozyskiwaniem danych z terenu jest wiele rodzajów pytań – o teksty, zdjęcia, kody kreskowe, wyboru itd.',
    },
    sectionThree: {
      title: 'Eliminowanie logicznych błędów',
      firstP:
        'Użycie logiki przy tworzeniu szablonu raportu pozwala zaoszczędzić czas poświęcany na korygowanie otrzymywanych informacji, do których często wkradają się zwykłe ludzkie błędy. Użytkownik aplikacji zostanie poproszony o wpisanie danych z zachowaną przez system ogólną logiką procesu. Np. jeśli produkt, o który pytamy jest niedostępny, system nie poprosi użytkownika o wykonanie jego zdjęcia czy też podanie ceny. Jeżeli akcja promocyjna nie była realizowana w wybranym punkcie sprzedaży, nie zapytamy o jej rezultaty.',
      secondP:
        'Zastąpienie chaotycznych informacji wysyłanych mailem lub za pośrednictwem komunikatorów takim przemyślanym i intuicyjnym system, pozwala zaoszczędzić 75 do 90% czasu poświęcanego na pracę związaną z przygotowaniem danych do analizy. Feedback przychodzi w czytelnej, gotowej do wykorzystania formie.',
    },
    sectionFour: {
      title: 'Dane w czasie rzeczywistym',
      firstP:
        'Przy stosowaniu zwinnego raportowania informacje, w oparciu o które managerowie oceniają sytuację w sieci sprzedaży, rzetelnie odzwierciedlają rzeczywistość. Przy tak dużej dynamice rynku ryzykowne jest podejmowanie decyzji na podstawie np. trendów z poprzedniego kwartału, a nawet miesiąca. Działanie bazujące na aktualnych danych pozwala skutecznie eliminować negatywny wpływ bezwładności organizacyjnej także w rozbudowanych strukturach!',
    },
    sectionFive: {
      title: 'Alerty dotyczące kluczowych zdarzeń',
      firstP:
        'System powiadomień to bezcenny pomocnik managera. Nawet, jeżeli w codziennym zabieganiu zapomnimy zajrzeć do cHow, aby sprawdzić, czy wydarzyło się w sieci coś wymagającego natychmiastowej reakcji, nie przeoczymy nic krytycznego – odpowiednio skonfigurowane alerty przychodzą w postaci maili lub komunikatów push na telefon.',
    },
    sectionSix: {
      title: 'Natychmiastowe przekazywanie danych',
      firstP:
        'Wdrożenie systemu do zwinnego raportowania sprawia, że dane w kilka sekund po ich zebraniu przez użytkownika stają się dostępne dla wszystkich uprawnionych osób wewnątrz organizacji. To istotne usprawnienie w porównaniu z procesem, który zakłada np. zrobienie zdjęcia, przesłanie go do komputera, połączenie z tabelą wypełnioną w Excelu i wysłanie maila do osoby zarządzającej najwcześniej kilka godzin po odbyciu wizyty w danym punkcie sprzedaży. A to dopiero pierwszy krok – daleko od momentu, w którym komplet danych z sieci będzie dostępny i wartościowy z punktu widzenia analizy informacji.',
    },
    sectionSeven: {
      title: 'Celowy, ale szeroki dostęp do informacji',
      firstP:
        'Niejednokrotnie okazuje się, że działy firmy pozyskując dane do swojego użytku nie komunikują tego faktu innym departamentom, które także mogłyby wykorzystać te informacje w swoich procesach. Działania bywają powielane, co generuje dodatkowe koszty. Częściej jednak decyzje w firmach są podejmowane bez wykorzystania wystarczającej puli informacji, w oparciu o intuicję, doświadczenie i potrzeby jednego działu – bez szerszego kontekstu. System do zwinnego raportowania pozwala tak zarządzać dużymi zbiorami drobnych, lecz bardzo istotnych z punktu widzenia firmy informacji, aby korzystało z nich więcej osób. Przekłada się to na jakość decyzji podejmowanych w całej organizacji.',
    },
    quote:
      '„cHow dostarcza informacje, z których - mogę to dziś z całą odpowiedzialnością powiedzieć – w formie raportów korzysta cała firma.  Katalog raportów został tak zbudowany, że w zasadzie cHow stał się głównym kanałem wymiany informacji między sklepem, a strukturami zarządczymi i logistyką.” ',
    author: 'Dyrektor Operacyjny Monnari Trade Piotr Olczyk',
    buttonText: 'Umów się na demo',
  },
  ENG: {
    img: 'raportuj-icon.png',
    desc: 'Katarzyna Zalass - Digital Transformation Architect',
    title: 'Meet agile reporting – decide without guessing',
    excerpt:
      'You’re wasting hours looking for data in emails and tables just to find out that the situation in you retail chain might be. After a tedious process of searching, it often turns out that the data you consider essential are not available or badly categorized. They need to be processed. Hours pass… Even if they are all out there, due to the differences in the logic under which they were stored it’s difficult to figure out answers to the most bothering questions. The time flies inevitably. When you finally arrive to conclusions you were aiming at and want to start acting, the situation in the retail chain will already been different. But different how?',
    sectionOne: {
      firstP:
        'There’s a simple way not to be bound to make decisions based on, for instance, trend data from the previous month – and making partial guesses. This way is agile reporting. ',
      list: {
        pos1: 'Chunks of field data run down to a single point',
        pos2: 'They are standardized',
        pos3: 'They contain all the expected elements',
        pos4:
          'Thanks to a cohesive data organization system it is easy to provide all the team members with the constant access to relevant information',
      },
      secondP:
        'From the standpoint of people working with distributed teams, agile field reporting stands for acquiring high quality data, easier to use in the day-to-day decision-making. Owing to this, actions can be carried out instantly, corrections can be made – and new insights into the situation appear almost constantly.',
      thirdP:
        'Thanks to the agile reporting systems – such as cHow – the data become organizations’ foundation for all decision-making processes.',
    },
    sectionTwo: {
      title: 'Cohesive data – no need for preprocessing',
      firstP:
        'Effective reporting needs acquiring data in a form that’s ready for analysis. Let’s suppose we’re gathering information on a price of a certain product – it might happen that different people enter it in a different way, like 5.25, 5,25 or $5.25. If this happens, before starting any analysis, you need to spend a lot of time normalizing the data. To avoid that, all you need to do is to use a specialized question type in the reporting tool – the users will be forced to enter the data only in the desired form since no other will be accepted. ',
      secondP:
        'The data acquisition managers have a lot of question types at their disposal – be it for freeform answers, for preprogrammed choices, for pictures, for barcodes etc. ',
    },
    sectionThree: {
      title: 'Avoiding logical errors',
      firstP:
        'Using the question logic while creating the survey template allows for saving a lot of time usually spent on correcting the acquired information, often riddled with ordinary human errors. The user will be asked to provide answers according to the overall process logic stored within the system. For instance, if the product we’re asking for is not available, the system won’t ask the user to take its picture or enter its price. If the promotional action was not conducted at a particular location, we will not ask for it’s outcome. ',
      secondP:
        'Replacing the chaotic information sent over email or over instant messengers with a thought-over and intuitive reporting system allows for saving up to 75 to 90% of the time spent on preparing data for further analysis. The feedback arrived in a concise, ready to use form. ',
    },
    sectionFour: {
      title: 'Real time data',
      firstP:
        'When using agile reporting, the data used by the managers to assess the situation in the retail chain, faithfully reflect the reality. Considering the dynamics of the market it is risky to make decisions based on the trends dating back to the last quarter or even the last month. Taking action based on the up-to-date information eliminates the inertia also in vast organizations. ',
    },
    sectionFive: {
      title: 'Key event notifications',
      firstP:
        'Notification system is a priceless helper tool for any manager. Even if, busy with everyday chores, we forget to visit the cHow dashboard to make sure there’s noting that would require immediate attention – properly configured alerts will arrive in the form of e-mail or push notifications.',
    },
    sectionSix: {
      title: 'Instant data availability',
      firstP:
        'Deploying an agile reporting tool gives you the ability to make the acquired data available for all the designated parties in the organization within the matter of seconds. It is a crucial improvement over the process consisting of, i.e., taking a picture, sending it over to a computer, combining it with an Excel spreadsheet and sending over email to the manager, no sooner than a couple of hours after making a visit to a particular location. And this is just the first step – not even near the moment when the data is available for analysis in their entirety. ',
    },
    sectionSeven: {
      title: 'Targeted yet broad access to information',
      firstP:
        'It often turns out that different departments withing the organization, when gathering some data for their own purposes, do not communicate them to other divisions so they can use them to power their own processes. Actions overlap, which generates additional costs. More often though, the decisions are being made without the sufficient information pool, by trusting experience, intuition and needs of a single department – without a broader context. An agile reporting system allows for managing large sets of small but crucial pieces of data to make them usable for a broader audience withing the organization. And this affects the quality of decisions all across the board. ',
    },
    quote:
      'cHow delivers the data which – I can express it with full responsibility today – is being accessed as report by the entire company. The catalogue of reports has been crafted in a way that essentially cHow has become the main channel for information exchange between the store, the management and the logistics',
    author: '– Piotr Olczyk, Managing Director at Monnari Trade.',
    buttonText: 'SCHEDULE A DEMO',
  },
};
