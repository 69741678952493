import React, { useContext, useEffect } from 'react';
import useBrowserLanguage from '../hooks/useBrowserLanguage';
import { Context } from './context';

const LanguageSwitcher = () => {
  const context = useContext(Context);
  if (!context) return null;

  const [state, dispatch] = context;
  const browserLanguage = useBrowserLanguage();

  useEffect(() => {
    if (browserLanguage === 'PL') {
      dispatch({ type: 'SET_PL' });
    } else {
      dispatch({ type: 'SET_ENG' });
    }
  }, [browserLanguage]);
  return null;
};

export default LanguageSwitcher;
