import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useContent from '../../content/useContent';
import { scrollToId } from '../../utils/scrollToId';

const MobileNavigation = ({ isVisible, setIsVisible, type, blog }) => {
  const content = useContent('header');
  const { pages } = content;

  const getAnimationClass = () => {
    if (isVisible === null) return '';
    return isVisible ? 'show' : 'hide';
  };

  const scrollToSection = (pageId) => {
    scrollToId(pageId);
    setIsVisible(false);
  };

  return (
    <nav className='mobile-navigation'>
      <div className={`mobile-navigation__inside ${getAnimationClass()}`}>
        {type !== 'blog'
          ? pages.map(({ pageName, isBtn, pageId }) => (
              <a
                key={pageName}
                onClick={() => scrollToId(pageId)}
                className={`${isBtn ? 'btn btn--1' : 'header__link'}`}
              >
                {pageName}
              </a>
            ))
          : blog &&
            blog.map(({ pageName, isBtn, pageId }) => (
              <a
                key={pageName}
                onClick={() => scrollToId(pageId)}
                className={`${isBtn ? 'btn btn--1' : 'header__link'}`}
              >
                {pageName}
              </a>
            ))}
      </div>
    </nav>
  );
};

MobileNavigation.propTypes = {
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
};

export default MobileNavigation;
