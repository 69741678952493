export const forWho = {
  PL: {
    title: 'Dla kogo jest cHow?',
    tabs: [
      {
        tabId: 1,
        tabTitle: 'Branże',
        tabContent: [
          'Sieci sprzedaży detalicznej - do porządkowania procesów, komunikacji i bieżącego raportowania oraz łatwego analizowania danych',
          'Firmy kolportażowe i dostawcze - do szybkiego raportowania powtarzalnych zadań z terenu',
          'Firmy budowlane i produkcyjne - do zgłoszeń i checklist (BHP, kontrola jakości, instrukcje)',
          'Merchandiserzy - do przekazywania wytycznych, raportowania zrealizowanych zadań, kontroli standardów',
          'Firmy i organizacje szukające rozwiązania porządkującego procesy, zadania i zgłoszenia - niezależnie od branży',
        ],
      },
      {
        tabId: 2,
        tabTitle: 'Działy',
        tabContent: [
          'Sprzedaży i marketingu - do bieżącej weryfikacji realizacji założeń akcji promocyjnych, poprawności oznaczeń produktów, ekspozycji',
          'HR - do zbierania informacji o brakach kadrowych, wysyłania instrukcji, testowania wiedzy pracowników, komunikacji także w formie skrzynki pomysłów',
          'VM - do wysyłania wytycznych dotyczących ekspozycji, zbierania raportów dotyczących sytuacji w sklepach i wysyłania informacji zwrotnej',
          'Rozwoju sieci - do monitorowania sytuacji w otoczeniu punktów sprzedaży - zamknięcia, otwarcia sklepów, remonty,remonty dróg w okolicy itp.',
          'BHP - do zbierania raportów wykonania procedur, zbierania informacji o zagrożeniach w miejscu pracy i raportów dotyczących ich usunięcia',
          'Operacyjny - do bieżącego raportowania między Centrum Zarządzania a punktami sprzedaży, monitorowania wykonania w projektach w odniesieniu do planu',
        ],
      },
    ],
    button: { buttonText: 'Umów się na demo' },
  },
  ENG: {
    title: 'Who is cHow for?',
    tabs: [
      {
        tabId: 1,
        tabTitle: 'Industries',
        tabContent: [
          'Retail chains - for process organization, communication, ongoing reporting and easy data analysis.',
          'Distribution companies - for rapid reporting of repeatable field tasks.',
          'Construction and production companies - for checklists and event reporting (health and safety, quality assurance, assembly instructions)',
          'Merchandisers - for exchanging guidelines, reporting the activities, standard control.',
          'Companies and organizations seeking to streamline their processes, assignments and reports - irrespective of the industry.',
        ],
      },
      {
        tabId: 2,
        tabTitle: 'Corporate divisions',
        tabContent: [
          'Sales and marketing - for ongoing verification of the promotional activity, accuracy of product marking and exposure.',
          'HR - for gathering information about staff shortages, sending out instructions, testing the employee knowledge, communication - also in the form of an "idea box".',
          'VM - for sending out guidelines regarding product placement, gathering reports about the situation in remote locations and sending feedback.',
          'Chain development - for monitoring the situation around the points of sale - opening or closing competition stores, roadblocks, renovations etc.',
          'Health and safety - for gathering reports about procedure adherence, accumulating hazard reports and confirmations of eliminating them.',
          'Operations - for ongoing reporting between the Headwuarters and the repote points of sale, monitoring the progress against the planned assumptions.',
        ],
      },
    ],
    button: { buttonText: 'Schedule a demo' },
  },
};
