export const blogPost2 = {
  PL: {
    img: 'post-title-zdrowy.png',
    desc: 'Katarzyna Zalass - Digital Transformation Architect',
    title: 'Nowe potrzeby klientów - nowe oblicze sklepów',
    excerpt:
      'Klienci nie czuliby się dziś komfortowo w takich sklepach, jakie wspominamy sprzed pandemii. Brak środków do dezynfekcji, zakupy bez masek, czekanie w długiej kolejce, w której ktoś stojący za nami trąca nas trzymanymi w ręku rzeczami – to się już właściwie nie mieści w głowie. W jakich warunkach klienci czują się teraz bezpiecznie? Jakie elementy uważają za najbardziej istotne dla budowania pozytywnych doświadczeń zakupowych?',
    firstP:
      'W opublikowanym przez KPMG z końcem września raporcie na temat zmiany zwyczajów zakupowych Polaków czytamy, że czynnikiem, który w czasie pandemii najbardziej zyskał na znaczeniu w oczach konsumentów jest własne bezpieczeństwo – grupa osób, dla której jest ono ważne, wzrosła o 58%<sup>1</sup>. Na liście niedogodności pojawiających się w czasie zakupów w sklepach stacjonarnych znalazły się między innymi nieprzestrzeganie zasad bezpieczeństwa przez innych klientów, kolejki, brak dostępnych środków ochrony osobistej. Informacje te stanowią skrócony opis rzeczywistości, w której próbuje obecnie funkcjonować tradycyjny handel.',
    sectionOne: {
      title: 'Wyzwanie – poczucie bezpieczeństwa',
      firtsP:
        'Detaliści stają dziś przez trudnym wyzwaniem: stworzenia w sklepach atmosfery sprzyjającej zakupom, a więc zapewnienia klimatu spokoju, profesjonalizmu i zaufania. Czy to w ogóle możliwe w świecie, w którym w trosce o zdrowie zachęcamy do utrzymywania dystansu społecznego, skracania czasu wizyt w sklepach, a podstawowa wymiana zdań bywa kłopotliwa, ponieważ trudno czasami zrozumieć przekaz płynący z ust przysłoniętych maską? Jak, mimo rosnącej niepewności, zapewnić poczucie bezpieczeństwa w procesie zakupów zarówno klientom jak i pracownikom? Uciążliwości wynikające z funkcjonowania w pandemicznej rzeczywistości dotykają przecież zarówno klientów jak i personel. A zaniepokojony, poddenerwowany personel nie pomoże z pewnością klientom poczuć się dobrze w czasie wizyty w sklepie, co z kolei będzie miało przełożenie na poziom sprzedaży, ogólne wrażenia dotyczące marki, chęć powrotu i tak dalej…',
    },
    sectionTwo: {
      title: 'Pracownicy wiedzą',
      firstP:
        'W zarządzaniu od dawna powtarzana jest prawda, że firma to ludzie. Że to o nich trzeba się troszczyć w pierwszej kolejności. W organizacjach bywało i bywa oczywiści różnie, ale COVID-19 z pewnością sprawił, że managerowie sieci handlowych przypomnieli sobie o tym, że to od pracowników funkcjonujących lokalnie zależy los globalnych struktur. Nową rolę zyskali kierownicy sklepów, którzy stali się niemal z dnia na dzień źródłem bezcennych dla firmy informacji. To oni znają realia w swoich regionach, widzą, co dzieje się w punktach handlowych w okolicy, obserwują reakcje mieszkańców na nowe zasady, obostrzenia czy rozluźnienia związane z reżimem sanitarnym. Rozmawiają każdego dnia z klientami, którzy dzielą się uwagami, dają wyraz obawom, proszą o rady. Mówiąc w skrócie – to oni wiedzą.',
    },
    sectionThree: {
      title: 'Pracownicy to gospodarze',
      firstP:
        'To nie zarządy firm a pracownicy mają decydujący wpływ na to, jak poczują się klienci odwiedzający sklep. Pełniąc rolę gospodarzy budują atmosferę. Jedni robią wszystko, co w ich mocy, aby mimo procedur dezynfekcji, obowiązku zasłaniania twarzy i nosa oraz zachowywania dystansu klient czuł się doskonale obsłużony. Inni chowają się za przyłbicami, ladami, regałami i wysyłają wszelkie werbalne i niewerbalne sygnały zmierzające do zniechęcenia klienta do pozostania w ich sklepie choćby minutę dłużej. Osoby zarządzające nie mają bezpośredniego wpływu na to, jak bardzo pracownicy boją się o swoje zdrowie ani na to, jak się zachowują. Mają jednak narzędzia, które mogą wykorzystać do budowania atmosfery zaufania, poczucia bezpieczeństwa i w ten sposób pomagać w kształtowaniu dobrego klimatu w sklepach.',
    },
    sectionFour: {
      title: 'Koncentracja na detalach, słuchanie i reagowanie',
      firstP:
        'Sprecyzowane zasady dotyczące stosowania środków ochrony osobistej i wszelkie związane z bezpieczeństwem procedury to z pewnością podstawa świadcząca o tym, że managerowie rozumieją powagę sytuacji w każdym z punktów sprzedaży i nie pozostają na nią obojętni. Procedury stanowią dobry punkt wyjścia – porządkują sprawy, co jest szczególnie istotne, gdy działaniu towarzyszy niecodzienny poziom stresu. Nie będą one jednak respektowane, jeśli nie zadbamy o kilka istotnych spraw:',
      list: {
        pos1:
          'Zaopatrzenie w niezbędne materiały - nie ma na przykład sensu rozliczanie pracowników z realizacji procedury dezynfekcji, jeśli nie ma to tego środków.',
        pos2:
          'Monitorowanie sytuacji w poszczególnych punktach sprzedaży jest niezbędne. Czy pracownicy postępują tak, jakbyśmy sobie tego życzyli? Aby trzymać rękę na pulsie warto udostępnić przyjazną metodę przekazywania rutynowych informacji między sklepami a centrum zarządzania. Firmy stosują rozmaite praktyki – od maili przez komunikatory, współdzielone dokumenty aż po aplikacje do raportowania, które ułatwiają zarówno pozyskiwanie jak i analizę dużej ilości danych.',
        pos3:
          'Sprawne reagowanie na zmiany i incydenty to coś, bez czego nie może już funkcjonować żadne przedsiębiorstwo. O sytuacji epidemiologicznej myślimy dziś w kontekście powiatów i przynajmniej tak nisko powinniśmy schodzić przy analizowaniu sytuacji sklepów w sieciach sprzedaży. Niezbędne jest umożliwienie pracownikom dzielenia się z centrum zarządzania informacjami o tym, co dzieje się lokalnie, czy pojawiły się nowe przypadki zachorowań lub kwarantanny przekładające się na braki kadrowe, czy klienci występują z istotnymi dla firmy prośbami, wnioskami albo pytaniami itd. Maile to stanowczo za mało. Wiele z nich umyka, wiele pozostaje bez odpowiedzi, czasami trudno je skategoryzować. Dlatego niewiele sygnałów od pracowników z sieci sprzedaży spotyka się z jakąkolwiek reakcją ze strony centrali. Dużym ułatwieniem są systemy dedykowane zbieraniu i analizie rozproszonych danych z licznych kategorii tematycznych jak c-How.io. Zgłoszenia są grupowane w kategoriach i zamieniane w zadania dla osób odpowiedzialnych za poszczególne obszary. Pracownik w każdej chwili może sprawdzić, co dzieje się ze zgłoszoną przez niego sprawą czy pytaniem. Ten z pozoru drobiazg sprawia, że ludzie chętniej się angażują. Wiedzą, że inicjatywa ma sens, a informacje nie są ignorowane. Czują się w tej kryzysowej sytuacji współodpowiedzialni zafunkcjonowanie firmy, a przecież dokładnie o to chodzi!',
      },
    },
    sectionFive: {
      title: '„Zdrowe sklepy”',
      firstP:
        'Czy klienci będą chcieli odwiedzać stacjonarne punkty sprzedaży, zależy w dużej mierze od tego, czy detalistom uda się stworzyć „zdrowe sklepy”, czyli takie, w których zarówno pracownicy jak i klienci będą się czuli bezpiecznie. Tylko po zaspokojeniu potrzeby poczucia bezpieczeństwa u obu stron może być mowa o budowaniu atmosfery sprzyjającej zakupom. W tej wyjątkowej dla handlu detalicznego sytuacji, w której więcej uwagi przywiązuje się do dostępności płynów odkażających niż do produktów i cen, przetrwają tylko zwinnie zarządzane organizacje. Konieczne są zmiany w większości procesów związanych z komunikacją, monitorowaniem sytuacji w punktach sprzedaży oraz elastycznością reagowania na bieżące wydarzenia. Musimy zdobyć umiejętność patrzenia na poszczególne sklepy jak na mikrocentra wydarzeń, a nie elementy zunifikowanej siatki. Widzieć w nich ludzi, a nie etaty i przedstawicieli segmentów. Pytać, słuchać, reagować. Optymiści starają się znajdować rzeczy, za które są „wdzięczni pandemii”. Może zetknięcie z tą nową wymagającą sytuacją będzie dla managerów inspirujące?',
    },
    additional: `J. Karasek, A. Musiał, K. Gaponiuk, Nowa rzeczywistość: konsument w dobie COVID-19. Jak zmieniły się
    zwyczaje zakupowe Polaków w czasie koronawirusa? KPMG, wrzesień 2020`,
  },
  ENG: {
    img: 'post-title-zdrowy.png',
    desc: 'Katarzyna Zalass - Digital Transformation Architect',
    title: 'New Customer Needs - the New Face of the Store',
    excerpt:
      'Customers today would no longer feel comfortable in the kind of stores we remember from before the pandemic. No hand sanitizers, shopping without masks, waiting in a long line with someone behind us knocking into us with the things held in their hands - this is no longer imaginable. Under what conditions do customers now feel safe? What elements do they consider the most important for building a positive shopping experience? ',
    firstP:
      'In a report published by KPMG at the end of September on the topic of changing shopping habits of Poles, we read that the factor that has gained the most importance in the eyes of consumers during the pandemic is their own safety - the group of people for whom this is important has increased by 58%.1. The list of inconveniences appearing during shopping in stationary stores includes, among others, non-compliance with safety rules by other customers, long lines, lack of available personal protective equipment. This information is an abbreviated description of the reality in which traditional retail is currently trying to operate.',
    sectionOne: {
      title: 'The challenge - feeling safe',
      firstP: `Retailers today are facing a difficult challenge: to create a shopping atmosphere in stores that is calm, professional and trustworthy. Is this even possible in a world where, for the sake of health, we are encouraged to keep social distance, to shorten the time spent in stores, and where basic conversations can be awkward because it is sometimes difficult to understand the message coming from a mouth covered with a mask? How, despite the growing uncertainty, to ensure a sense of safety in the shopping process for both customers and employees? After all, the inconveniences resulting from functioning in a pandemic reality affect both customers and staff. And anxious, cranky staff certainly won't help customers feel good about their visit to the store, which, in turn, will translate into sales, overall brand impressions, the willingness to return, and so on....`,
    },
    sectionTwo: {
      title: 'Employees know',
      firstP: `It's a long-standing truth in management that a company is made up of people. That they are the ones who need to be taken care of first. Of course, things can and do vary in organizations, but COVID-19 certainly made retail chain managers remember that the fate of global structures depends on locally operating employees. Store managers gained a new role. They became a source of invaluable information for the company practically overnight. They are the ones who know the realities in their regions, they see what is happening in the retail outlets in the area, they observe the reaction of the residents to new the rules, restrictions or relaxations of the sanitary regime. They talk every day with customers who share comments, express concerns, and ask for advice. In short, they know.`,
    },
    sectionThree: {
      title: 'Employees are the hosts',
      firstP:
        'Employees, not company management, have a decisive influence on how customers feel when they visit a store. In their role as hosts, they create the atmosphere. Some do everything in their power to make customers feel perfectly well-served, despite disinfection procedures, the obligation to cover faces and noses and to keep their distance. Others hide behind visors, counters, and shelves and send all kinds of verbal and nonverbal signals designed to discourage the customer from staying in their store even one minute longer than they need to. Managers have no direct influence on how fearful employees are of their health or how they behave. They do, however, have tools they can use to build an atmosphere of trust, a sense of security, and thus help shape a good store atmosphere. ',
    },
    sectionFour: {
      title: 'Focusing on details, listening and responding',
      firstP:
        'A clear policy on the use of personal protective equipment (PPE) and all safety-related procedures are certainly essential to ensure that managers understand the seriousness of the situation in each outlet and are not indifferent to it. Procedures are a good starting point - they put things in order, which is especially important when the activity is accompanied by an unusual level of stress. However, they will not be respected unless a few important things are taken care of:',
      list: {
        pos1:
          'Supply of necessary materials - for example, there is no point in holding employees accountable for implementing a disinfection procedure if the means to do so are not available.',
        pos2:
          'Monitoring the situation at individual outlets is essential. Are employees acting as we would like them to? To make sure your eye is on the ball it is definitely worth providing a friendly method of communicating routine information between stores and the management center. Companies use a variety of practices-from emails to instant messaging to shared documents to reporting applications-that make it easy to both capture and analyze large amounts of data.',
        pos3:
          'Responding efficiently to changes and incidents is something that no company can function without anymore. We think of the epidemiological situation today in terms of counties, and we should go at least to that level when analyzing the situation of stores in chain stores. It is necessary to enable employees to share information with the management center about what is happening locally, whether there are new cases of disease or quarantine that translate into staff shortages, whether customers have important requests, proposals or questions, etc. Emails are definitely not enough. Many of them get lost, many remain unanswered, sometimes difficult to categorize. That is why very few signals from sales network employees meet with any response from the head office. Systems dedicated to collecting and analyzing distributed data from numerous thematic categories, such as c-How.io, are a great help. Notifications are grouped in categories and turned into tasks for people responsible for particular areas. An employee can check at any time what is happening with the issue or question they have submitted. This seemingly small detail makes people more willing to get involved. They know that the initiative makes sense and the information is not ignored. They feel co-responsible for the functioning of the company in this crisis situation, and after all, this is exactly what it is all about!',
      },
    },
    sectionFive: {
      title: '"Healthy stores"',
      firstP:
        'Whether customers will want to visit stationary outlets depends largely on whether retailers succeed in creating "healthy stores", i.e. stores where both employees and customers feel safe. Only after satisfying the need for both parties to feel safe can there be any question of creating an atmosphere conducive to shopping. In this unique retail situation, where more attention is paid to the availability of disinfectants than to products and prices, only nimbly managed organizations will survive. Changes are needed in most processes related to communication, the monitoring of point-of-sale situations, and flexibility to respond to current events. We need to gain the ability to look at individual stores as microcenters of events rather than elements of a unified grid. See them as people, not jobs or segment representatives. Ask, listen, respond. Optimists try to find things for which they are "grateful to the pandemic." Perhaps encountering this new challenging situation will be inspiring for managers?',
    },
    additional: `J. Karasek, A. Musiał, K. Gaponiuk, The New Reality: the Consumer in the Era of COVID-19. How Have Poles' Shopping Habits Changed During the Coronavirus? KPMG, September 2020`,
  },
};
