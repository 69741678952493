import React, { useContext } from 'react';
import useContent from '../../content/useContent';
import { Context } from '../../store/context';

const Footer = () => {
  const company = useContent('company');
  const { companyName } = company;

  const currentYear = new Date().getFullYear();

  // const [state, dispatch] = useContext(Context);

  const context = useContext(Context);
  if (!context) return null;

  const [state, dispatch] = context;

  const { language } = state;

  return (
    <footer className='footer section section--dark'>
      <div className='footer__inside boxed'>
        <p className='f5'>
          Copyright © {companyName} {currentYear}
        </p>
        <p className='footer-switch'>
          <button
            className={language === 'PL' ? 'disabled' : ''}
            onClick={() => dispatch({ type: 'SET_PL' })}
            type='button'
          >
            PL
          </button>
          <span> | </span>
          <button
            className={language === 'ENG' ? 'disabled' : ''}
            onClick={() => dispatch({ type: 'SET_ENG' })}
            type='button'
          >
            ENG
          </button>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
